import Img from "gatsby-image"
import React from "react"
import { ProjectType } from "../../types"
import ProjectIcon from "./project-icon"
import ProjectStatus from "./project-status"
import ProjectTags from "./project-tags"

const Project = props => {
  const { name, image, liveUrl, githubUrl, description, status, tags, icon } = props
  return (
    <div className="border-t-4 border-line relative flex flex-wrap bg-back-light p-4 lg:p-8 bg-no-repeat text-sm mb-6">
      {image && (
        <div className="w-full pb-4 lg:w-2/5 lg:pr-8 lg:pb-0">
          <Img fluid={image.childImageSharp.fluid} alt={name} />
        </div>
      )}
      <div className="lg:flex-1">
        <h4 className="font-bold">{name}</h4>
        <div className="flex flex-col">       
          {liveUrl && (
            <a
              className="text-front underline hover:opacity-75 transition-opacity duration-150"
              href={liveUrl}
              rel="noreferrer noopener"
              target="_blank"
            >
              {liveUrl}
            </a>
          )}
          {githubUrl && (
            <a
              className="text-front underline hover:opacity-75 transition-opacity duration-150"
              href={githubUrl}
              rel="noreferrer noopener"
              target="_blank"
            >
              {githubUrl}
            </a>
          )}
        </div>
        <p className="w-full py-4 whitespace-pre-line">{description}</p>
        <ul className="pr-2">
          {status && <ProjectStatus status={status} />}
          {tags && <ProjectTags tags={tags} />}
        </ul>

        {icon && <ProjectIcon icon={icon} />}
      </div>
    </div>
  )
}

Project.propTypes = ProjectType

export default Project
